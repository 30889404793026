.signup-section {
  background-color: #508bfc;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.signup-card {
  border-radius: 1rem;
}

.signup-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
