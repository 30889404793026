.login-section {
  background-color: #508bfc;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.login-card {
  border-radius: 1rem;
}

.login-google-btn:hover {
  background-color: #dd4c39cc;
}

.login-google-btn-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0.25rem;
}

.login-google-icon {
  margin-right: 0.5rem;
}

.login-cursor {
  cursor: pointer;
}
