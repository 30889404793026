.modal-wrapper {
  background-color: #fff;
  padding: 2.5rem 6rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-subtext {
  font-size: 0.8rem;
  color: gray;
}

.modal-green {
  color: green;
}
