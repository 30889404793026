.header {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 1.5rem;
  cursor: pointer;
}

.header-icon-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.header-icon {
  font-size: 1.5rem;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .header-account {
    display: none;
  }
}
